import { CSSProperties, ReactNode } from 'react'
import { Badge } from 'antd'

import type { IRecord } from '../interface'
import { useSTATE_NAME_MAP } from '../utils'

/** 提货状态显示 */
export const StateShow = ({
  state,
  after,
  ...rest // 气泡需要
}: { style?: CSSProperties; after?: ReactNode } & Pick<IRecord, 'state'>) => {
  const stateNameMap = useSTATE_NAME_MAP()

  const colorMap: Record<number, string | undefined> = {
    1: undefined, // 待审核
    2: 'purple', // 生产中
    3: 'blue', // 运输中
    4: 'cyan', // 入库中
    5: 'green', // 已入库（已上架）
    6: 'red', // 已取消
    7: 'green', // 已完成
  }

  return (
    <Badge
      {...rest}
      color={colorMap[state] || $COLOR.statusDefault}
      text={
        <>
          {stateNameMap[state] || state}
          {!!after && <span style={{ marginLeft: 2 }}>{after}</span>}
        </>
      }
    />
  )
}
